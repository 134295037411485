.dots {
    width: 4.5px;
    height: 4.5px;
    border-radius: 50%;
    color: #ffffff;
    box-shadow: 21.3px 0 0 7.8px, 42.6px 0 0 3.4px, 63.900000000000006px 0 0 0;
    transform: translateX(-42.6px);
    animation: dots-ijr34dmd 0.5s infinite alternate linear;
}

@keyframes dots-ijr34dmd {
    50% {
        box-shadow: 21.3px 0 0 3.4px, 42.6px 0 0 7.8px, 63.900000000000006px 0 0 3.4px;
    }

    100% {
        box-shadow: 21.3px 0 0 0, 42.6px 0 0 3.4px, 63.900000000000006px 0 0 7.8px;
    }
}