@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800&display=swap');

body {
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 14px;
}

@font-face {
  font-family: 'Bebas Neue Pro';
  src: url('@/assets/fonts/BebasNeuePro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
